.container {
  height: 100vh;
  display: grid;
  place-items: center;
  z-index: 10;
  /* -webkit-animation-name: dissapear; */
}

.disapear {
  -webkit-animation-name: dissapear;
  animation-name: dissapear;
  animation-duration: 1.5s;
  animation-delay: 5s;
}

.container>.envelopeWrapper {
  /* background: #f5edd1; */
  /* box-shadow: 0 0 40px rgba(0, 0, 0, 0.2); */
  position: relative;
}

.envelopeWrapper>.envelope {
  position: relative;
  width: 500px;
}

.envelope_abs {
  background-color: #f5edd1;
  width: 500px;
  height: 250px;
}

.letter {
  width: 320px;
  background-color: #f5edd1;
}

/* .envelopeWrapper > .envelope::before {
  content: '';
  position: absolute;
  top: 0;
  z-index: 2;
  border-top: 130px solid #ecdeb8;
  border-right: 150px solid transparent;
  border-left: 150px solid transparent;
  transform-origin: top;
  transition: all 0.4s ease-in-out 0.6s;
} */
.envelopImg {
  position: absolute;
  top: 0;
  width: 100%;
  transform-origin: top;
  transition: all 0.6s ease-in-out 0.8s;
  z-index: 4;
}

.envelopImg.envelopOpen {
  transform: rotateX(180deg);
  z-index: 1;
}

.envelopBodyImg {
  position: relative;
  top: 0;
  width: 100%;
}

/* .envelopeWrapper > .envelope::after {
  content: '';
  position: absolute;
  z-index: 2;
  width: 0px;
  height: 0px;
  border-top: 130px solid transparent;
  border-right: 150px solid #e6cfa7;
  border-bottom: 100px solid #e6cfa7;
  border-left: 150px solid #e6cfa7;
} */

.envelope>.letter {
  position: absolute;
  right: 8%;
  bottom: 5px;
  width: 84%;
  height: 96%;
  background: #fff;
  text-align: center;
  transition: all 0.8s ease-in-out;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  border-radius: 20px;
}

.envelope>.letter>.text::after {
  content: '';
  position: absolute;
  width: 100%;
  background: #fff;
  box-shadow: 1px 25px 9px 2px #fff;
  opacity: 0.6;
}

.envelope>.letter>.text {
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  color: var(--txt-color);
  text-align: left;
  font-size: 10px;
  position: relative;
  height: 100%;
}

.envelope>.letter>.text>img {
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  height: 100%;
}

.envelope>.letter>.text>P {
  text-align: center;
  font-size: 26px;
  text-transform: capitalize;
  color: #b28a58;
}

.letter.lineUptop>.text::after {
  -webkit-animation-name: uptotopLine;
  animation-name: uptotopLine;
  animation-duration: 2s;
  animation-delay: 2s;
}

.heartIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  background: rgb(252, 8, 231);
  z-index: 4;
  transform: translate(-50%, -20%) rotate(45deg);
  transition: transform 0.4s ease-in-out 0.6s;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.shadow_letter {
  position: absolute;
  width: 300px;
  top: 0;
  transform: translate3d(0px, 37px, 0);
}


.shadow_left {
  left: 0;
}

.shadow_right {
  right: 0;
}

.shadow .shadow_left,
.shadow .shadow_right {
  opacity: 0;
  transition: ease-in 2s;
}

.signIcon {
  position: absolute;
  left: 50%;
  width: 100px;
  height: 50px;
  z-index: 4;
  transform: translate(-50%, 0%);
  transition: transform 0.4s ease-in-out 0.6s;
  cursor: pointer;
  bottom: 150px;
}

.signIconRotate_bgLogo {
  position: absolute;
}

.signIconRotate_Logo {
  position: absolute;
  width: 33px;
  transform: translate(100%, 100%);
}

.heartIcon:before,
.heartIcon:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: rgb(252, 8, 231);
  border-radius: 50%;
}

.heartIcon:before {
  top: -7.5px;
}

.heartIcon:after {
  right: 7.5px;
}

.flap>.envelope:before {
  transform: rotateX(180deg);
  z-index: 0;
}

.flap>.envelope>.letter {
  -webkit-animation-name: uptotop;
  animation-name: uptotop;
  animation-duration: 4s;
  animation-delay: 2s;
}

.signIconRotate {
  position: relative;
}

.flap>.signIcon>.signIconRotate {
  -webkit-animation-name: opacity;
  animation-name: opacity;
  animation-duration: 2s;
  opacity: 0;
}

.heart img {
  width: 50px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: rotateY(0);
}


.close .heart {
  opacity: 0;
  animation: none;
  margin-bottom: -100px;

}

.heart {
  opacity: 1;
  transition: linear 1s;
}

.a1 {
  left: 20%;
  transform: scale(0.5);
  opacity: 1;
  animation: slideUp 10s linear 1, sideSway 1s ease-in-out 8 alternate;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  top: 40%;
}

.a2 {
  left: 80%;
  transform: scale(1);
  opacity: 1;
  animation: slideUp 10s linear 1, sideSway 1s ease-in-out 8 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
  top: 45%;
}

.a3 {
  left: 10%;
  transform: scale(0.8);
  opacity: 1;
  animation: slideUp 10s linear 1, sideSway 1s ease-in-out 6 alternate;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
  top: 70%;
}

.a4 {
  left: 33%;
  transform: scale(0.6);
  opacity: 1;
  animation: slideUp 10s linear 1, sideSway 1s ease-in-out 6 alternate;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  top: 83%
}

.a5 {
  left: 90%;
  transform: scale(0.4);
  opacity: 1;
  animation: slideUp 10s linear 1, sideSway 1.25s ease-in-out 6 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
  top: 25%;
}

.a6 {
  left: 75%;
  transform: scale(0.25);
  opacity: 1;
  animation: slideUp 10s linear 1, sideSway 1.25s ease-in-out 6 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
  top: 20%;
}

.a7 {
  left: 60%;
  transform: scale(0.5);
  opacity: 1;
  animation: slideUp 10s linear 1, sideSway 1.25s ease-in-out 6 alternate;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  top: 80%;
}

.a8 {
  left: 10%;
  transform: scale(0.4);
  opacity: 1;
  animation: slideUp 10s linear 1, sideSway 1.25s ease-in-out 6 alternate;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
  top: 60%;
}

@keyframes opacity {
  0% {
    opacity: 1;
    transform: scale(1.2);
    transition-delay: 0.3s;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0.5;
  }

  30% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  70%,
  80%,
  90%,
  100% {
    opacity: 0;
    top: 100px;
  }
}

@media (max-width: 768px) {
  @keyframes slideUp {
    0% {
      opacity: 0.5;
    }

    30% {
      opacity: 1;
    }

    50% {
      opacity: 0;
      top: 20%;
    }

    60% {
      opacity: 0;
      top: 10%;
    }

    70%,
    80%,
    90%,
    100% {
      opacity: 0;
      top: 200px;
    }
  }
}

@keyframes sideSway {
  0% {
    margin-left: 10px;
  }

  100% {
    margin-left: -20px;
  }
}

@keyframes backInDown {
  0% {
    -webkit-transform: translateY(-1200px) scale(0.7);
    transform: translateY(-1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateY(0) scale(0.7);
    transform: translateY(0) scale(0.7);
    opacity: 0.7;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.animate__outDown {
  position: absolute;
  -webkit-animation-name: outDown;
  animation-name: outDown;
  animation-duration: 6s;
  animation-delay: 2s;
}

.animate__backInDown {
  /* -webkit-animation-name: backInDown;
  animation-name: backInDown;
  animation-duration: 1.5s;
  animation-delay: 0.5s; */
}

.animate__outDown_signIcon {
  /* -webkit-animation-name: animate__outDown_signIcon;
  animation-name: animate__outDown_signIcon;
  animation-duration: 6s;
  animation-delay: 2s; */
}

@keyframes animate__outDown_signIcon {
  0% {
    transform: translate(-50%, 0%);
  }

  20% {
    transform: translate(-50%, 0%);
  }

  30% {
    transform: translate(-50%, 0%);
  }

  40% {
    transform: translate(-50%, 0%);
  }

  50% {
    transform: translate(-50%, 0%);
  }

  to {
    transform: translate(-50%, 2000%);
  }
}

@keyframes outDown {
  0% {
    top: 0;
  }

  20% {
    top: 0;
  }

  30% {
    top: 0;
  }

  40% {
    top: 0;
  }

  50% {
    top: 0;
  }

  to {
    top: 500%;
  }
}

@keyframes uptotop {
  0% {
    bottom: 0;
    opacity: 1;
  }

  40% {
    bottom: 230px;
    opacity: 1;
    overflow: visible;
  }

  100% {
    bottom: 230px;
    overflow: visible;
  }
}

@-webkit-keyframes uptotop {
  0% {
    bottom: 0;
  }

  70% {
    bottom: 230px;
    opacity: 1;
    overflow: visible;
  }

  100% {
    bottom: 230px;
    overflow: visible;
  }
}

@media (max-width: 991px) {
  @keyframes uptotop {
    0% {
      bottom: 0;
      opacity: 1;
    }

    40% {
      bottom: 160px;
      opacity: 1;
      overflow: visible;
    }

    100% {
      bottom: 160px;
      overflow: visible;
    }
  }

  @-webkit-keyframes uptotop {
    0% {
      bottom: 0;
    }

    70% {
      bottom: 200px;
      opacity: 1;
    }

    100% {
      bottom: 200px;
    }
  }
}

@keyframes uptotopLine {
  0% {
    top: 300px
  }

  100% {
    top: 0;
  }
}

@-webkit-keyframes uptotopLine {
  0% {
    top: 300px
  }

  100% {
    top: 0;
  }
}

@keyframes dissapear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width: 991px) {
  .envelopeWrapper {
    padding: 0 15px;
    top: 100px;
  }

  .envelopeWrapper>.envelope {
    position: relative;
    width: auto;
  }

  .shadow_letter {
    width: 230px;
    transform: translate3d(0px, 44px, 0);
  }

  .signIconRotate {
    position: relative;
    height: 150px;
  }

  .signIconRotate_bgLogo {
    bottom: 0;
  }

  .signIconRotate_Logo {
    transform: unset;
    bottom: 0;
    top: 50px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .shadow_letter {
    width: 155px;
    transform: translate3d(0px, 26px, 0);
  }
}