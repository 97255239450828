.header_top {
    border-bottom: 1px solid #fff;
    padding: 10px 0;
    box-shadow: 0px 0px 2px 0px var(--bg-pink-color);
    margin-bottom: 0;
}

.header_top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.styleImageLogo {
    width: 100%;
    max-width: 190px;
}

.navbar {
    display: flex;
}

.navbar_nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav_item {
    padding: 0 20px;
    position: relative;

}

.nav_link {
    font-family: var(--quick-sand-font-family);
    color: var(--black-color);
    font-weight: 600;
}

.nav_link:hover {
    color: var(--bg-pink-color) !important;
}

.dropdownbutton {
    background: var(--black-color);
    padding: 20px 20px;
    color: var(--white-color);
    min-width: 250px;
    position: absolute;
    font-family: var(--quick-sand-font-family);
    top: 70px;
    z-index: 999;
}

.dropdownbutton span {
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
}

.dropdownbutton p {
    margin-bottom: 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.headerTextCenter {
    text-align: center;
    display: grid;
    padding: 150px 0;
    grid-template-columns: none;
    grid-gap: 40px 40px;
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.textStyleFormat {
    color: var(--white-color);
    font-size: 60px;
    display: block;
    margin: 0 auto;
    text-shadow: 2px 2px 4px var(--black-color);
    margin-bottom: 20px;
}

.buttonBottom_box {
    display: flex;
    margin: auto;
    width: 100%;
    max-width: 350px;
    justify-content: space-around;
}

.styleBoxText {
    max-width: 650px;
    width: 100%;
    top: 100px;
}

.containerCustormStyle {
    position: relative;
}


.styleImgCustomBox_2 {
    position: absolute;
    transform: translate(0px, 27%);
    max-width: 630px !important;
    z-index: 9;
}

.styleImgCustomBox_3 {
    max-width: 700px !important
}

.boxInLineY {
    display: block;
    margin: 10px 0;
}

.lineHerizontalBox {
    display: flex;
    gap: 20px;
    margin: 15px 0;
    align-items: center;
}

.lineHerizontalBox img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.formatTextInline {
    font-family: var(--quick-sand-font-family);
    font-weight: 700;
    margin-bottom: 0;
    font-size: 19px;
}

.active {
    color: var(--bg-pink-color);
}

@media (min-width: 768px) {
    .styleImageLogoMB {
        display: none;
    }
}

@media (min-width: 991px) and (max-width: 1023px) {
    .header_top>div {
        max-width: 961px;
    }

    .textStyleFormat {
        font-size: 40px;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .textStyleFormat {
        font-size: 40px;
    }
}

@media (max-width: 991px) { 
    .header_top {
        padding: 20px 15px;
    }

    .headerTextCenter {
        padding: 50px 15px;
        width: 100%;
    }

    .textStyleFormat {
        font-size: 25px;
        max-width: 100%;
    }

    .containerCustormStyle {
        padding: 30px 0 !important;
    }

    .styleImgCustomBox_2 {
        position: relative;
        transform: unset;
    }

    .styleImgCustomBox_4 {
        position: relative;
        transform: unset;
        max-width: 300px !important;
        margin-top: 80px;
    }

    .navbar_nav {
        position: fixed;
        height: 100%;
        width: 70%;
        background: var(--white-color);
        top: 0;
        z-index: 999999;
        left: 0;
        display: block;
        overflow-x: hidden;
        -webkit-box-shadow: 0 2px 11px 1px rgba(168, 168, 168, 0.54);
        -moz-box-shadow: 0 2px 11px 1px rgba(168, 168, 168, 0.54);
        box-shadow: 0 2px 11px 1px rgba(168, 168, 168, 0.54);
        visibility: hidden;
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        -webkit-transform: translateX(-280px);
        -ms-transform: translateX(-280px);
        -o-transform: translateX(-280px);
        transform: translateX(-280px);
    }

    .nav_item {
        padding: 10px 20px;
        border-bottom: 1px solid var(--gray-color-2);
    }

    .nav_link {
        font-family: var(--quick-sand-font-family);
        color: var(--black-color);
    }

    .open {
        visibility: visible;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
    }

    .styleImageLogoMB {
        display: block;
        margin: 20px auto;
        width: 200px;
    }

    .dropdownbutton {
        position: relative;
        top: 0;
    }
}