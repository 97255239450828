@tailwind base;
@tailwind components;
@tailwind utilities;
/* font-family: 'Lateef', serif;
font-family: 'Playfair Display', serif;
font-family: 'WindSong', cursive; */
/*
=============== 
Global Styles
===============
*/
/* 
-FONT SIZE
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98  

-SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

:root {
  /* root letter page */
  --letter-bg-color-pink: '#FFF3F5';

  /* --letter-main-color: #91ada3; */
  --border-section-main: 1px solid rgba(145, 173, 163, 0.5);
  --border-section-1: 2px solid rgba(243, 135, 35, 0.1);
  --orange-color: rgba(243, 135, 35, 1);
  --letter-main-color: rgba(145, 173, 163, 1);
  --gray-item-color: 1px solid #c6c6c6;
  --border-img-dash: 2px dashed #828282;
  --color-hover: #cf9897;
  --color-red: #ff6f61;
  --color-input: #e2e8f0;
  /* root home page */
  --pink--bold: #f5eced;
  --pink-color-1: rgba(212, 150, 150, 1);

  --pink-color-2: #fbf3f1;
  --black-color: #000;
  --white-color: #fff;
  --gray-color: #c1c1c1;
  --gray-color-2: #f1f5f9;
  --gray-color-3: #94a3b8;
  --gray-color-4: #f4f4f4;
  --panel-color: #94a3b8;
  --text-color-head: #0f172a;
  --text-color-darkmode: #131825;
  --quick-sand-font-family: 'Quicksand', sans-serif;
  --lateef-font-family: 'Lateef', serif;
  --wind-song-font-family: 'WindSong', cursive;
  --font-size: 60px;
  --font-size-default: 16px;
  --red-color-mess-err: #ff0000;
  --bg-yellows-color: #fde9d7;
  --green-opacity-bg: #f1ede1;
  --bg-bold-green: #09102a;
  --gray-input-color: #7c7c7c;
  --bg-pink-color: #cf9897;
  --gray-Dark-Dark-Mode-50: #5f626b;
  --primary-Blue-color-400: #3460dc;
  --bg-tooltip-color: #208c64;
  --bg-tranparent-green: rgba(167, 189, 181, 0.2);
  --bg-pink-blur: #f6eaea;
  --rbga--pink-bold: rgba(127, 90, 90, 1);
  --bg-gray-color-letter-page: rgba(207, 207, 207, 1);
  --color-bold-pink: #7c5b5b;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-padding: 0;
  overflow-y: scroll;
}

body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Playfair Display', sans-serif;
  color: rgba(88, 88, 88, 1);
  font-size: var(--font-size-default);
}

body::-webkit-scrollbar {
  height: 100%;
  width: 100%;
  background: var(--gray-color-3);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background: var(--pink-color-1);
  border-radius: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-main {
  background: var(--white-color);
  box-shadow: 0px 13px 37px rgba(0, 0, 0, 0.21);
}

.lateef {
  font-family: 'Lateef', sans-serif;
}

.wind-song {
  font-family: 'WindSong', cursive;
}

.quick-sand {
  font-family: 'Quicksand', sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 12px;
}

h1 {
  /* 32px */
  font-size: 1.875rem;
}

h2 {
  /* 20px */
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
  /* 16px */
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 0.75rem;
  color: var(--clr-grey-3);
}

/*  global classes */
.dotted_scroll {
  position: fixed;
  right: 20px;
  top: 0;
  z-index: 9999;
  bottom: 0;
  margin: auto;
  display: flex;
}

.bg-letter-main-color {
  background-color: var(--letter-main-color);
}

.dotted_scroll ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.dotted_scroll ul li a {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  background: var(--white-color);
  display: block;
  cursor: pointer;
  transition: 0.4s width, 0.4s height;
}

.dotted_scroll ul li a.active {
  background-color: var(--bg-pink-color);
  width: 15px;
  height: 15px;
  transition: 0.4s width, 0.4s height;
}

.slide-container {
  position: relative;
}

.slide-container img {
  object-fit: cover;
  opacity: 0.8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  filter: brightness(0.5);
}

.main_tit .tit_type2 {
  font-style: italic;
  font-weight: 400;
  font-size: 40px;
  line-height: 130%;
  color: var(--white-color);
  text-shadow: 2px 2px 4px var(--black-color);
}

.second-text-pink {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--text-second);
}

.icon-container {
  position: relative;
}

.icon-absolute {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.link-map {
  display: block;
}

.btn-map {
  position: relative;
  background-color: #2968c7;
  color: white;
  border-radius: 40px;
}

.gg-map-icon {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
}

.border-section-1 {
  border-bottom: var(--border-section-1);
}

.border-section-main {
  border-bottom: var(--border-section-main);
}

.border-section {
  border-top: var(--border-section-main);
}

.margin-auto {
  margin: auto;
}

.border-gray-item-color {
  border: var(--gray-item-color);
}

.bg-white {
  background-color: white;
}

.border-img-dash {
  border: var(--border-img-dash);
}

/* layout restructor */
.letter-wrapper {
  scroll-behavior: smooth;
}

.letter-wrapper .animate__zoomInDown {
  animation-fill-mode: none;
}

.letter-layout {
  max-width: 560px;
  margin: auto;
  position: relative;
  box-shadow: 0px 13px 37px rgba(0, 0, 0, 0.21);
}

.image {
  position: absolute;
  z-index: 99999;
}

/* .letter-layout {
  -webkit-animation-name: gototiop;
  animation-name: gototiop;
  animation-duration: 0.5s;
} */

.letter-layout .modal-content {
  overflow: scroll;
}

.letter-layout .modal-content::-webkit-scrollbar {
  display: none;
}

.slider_cmt .close_icon {
  display: none;
}

@keyframes gototiop {
  0% {
    transform: translate(0, 100%);
  }

  60% {
    transform: translate(0, 50%);
  }

  to {
    transform: translate(0, 0);
  }
}

.letter-input {
  background-color: #c6c6c6;
}

.nav-btn-container {
  top: 0;
  right: 0;
}

.section {
  overflow: hidden;
  scroll-snap-align: start;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-snap-stop: normal;
  height: 100vh;
}

.step2_list {
  display: flex;
  gap: 20px;
  margin-top: 60px;
}

.step2_list li {
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
}

.step2_list li .tooltip {
  display: none;
  position: absolute;
  bottom: 145px;
  left: -100%;
  right: 0;
  padding: 5px 5px 7px;
  border-radius: 8px;
  background-color: var(--bg-tooltip-color);
  font-size: 0.8em;
  line-height: 1.5;
  text-indent: 0;
  color: var(--white-color);
  text-align: Center;
  width: 300px;
  z-index: 9999;
  transform: translate(15%, 0);
  font-weight: 400;
}

.step2_list li .tooltip::after {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--bg-tooltip-color);
  bottom: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.step2_list li:hover .tooltip {
  display: block;
}

.step2_list li:hover .tooltip strong {
  display: block;
  font-size: 17px;
}

.box_3phone_wrap_iconn_section .step2_list li .tooltip {
  bottom: 105px;
  left: -158%;
  background-color: var(--bg-pink-color);
  font-family: var(--quick-sand-font-family);
}

.box_3phone_wrap_iconn_section .step2_list li .tooltip::after {
  border-top: 6px solid var(--bg-pink-color);
}

.wrapbox_image_pc {
  background: url('../src/assets/home-image/bgsection.png') no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 40px;
  display: flex;
  height: 100%;
  position: relative;
}

.wrapbox_image_pc .head {
  text-align: center;
}

.wrapbox_image_pc .head h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 150%;
  color: var(--white-color);
}

.image_single_pc {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 65%;
  display: block;
}

.image_single_pc img {
  width: 100%;
}

.box_3phone_section {
  display: flex;
  align-items: center;
  background: var(--bg-pink-blur);
}

.box_3phone_wrap_image {
  display: flex;
  max-width: calc(100% - 200px);
  margin: 0 auto;
}

.box_3phone_wrap_image img {
  width: 100%;
}

.box_3phone_section .head {
  position: absolute;
  bottom: 50px;
  max-width: 400px;
  z-index: 9;
}

.box_3phone_section .head h3 {
  color: var(--black-color);
  font-size: 30px;
  margin-bottom: 0;
}

.box_3phone_section .step2_list {
  margin-top: 10px;
}

.choosetypeblock_box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--gray-color-2);
}

.box_top_footer {
  position: relative;
  overflow: hidden;
  background: url('../src/assets/home-image/IMG_BgTopFooter.png') no-repeat;
  background-size: cover;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box_top_footer .box_wrap .text {
  text-align: center;
}

.box_top_footer .box_wrap .text p {
  font-style: normal;
  font-weight: 200;
  font-size: 32px;
  line-height: 150%;
  color: var(--white-color);
}

.box_top_footer .box_wrap .text h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 150%;
  color: var(--white-color);
}

/* SCHEDULE */
.schedule-container {
  border-bottom: 1px solid var(--main-color);
  border-width: 80%;
}

.schedule-detail-img {
  margin-left: auto;
  min-width: 40%;
  margin-right: auto;
}

.schedule-bg-lr {
  min-width: 50px;
}

.schedule-bg-l {
  margin-right: -16px;
}

.schedule-bg-r {
  margin-left: -16px;
}

.schedule-bg {
  min-height: 100%;
  min-width: 100%;
}

.calendar {
  max-width: 320px;
  margin: 24px auto 24px;
  /* box-shadow: 1px 2px 3px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 6px;
}

.header {
  display: flex;
  border-radius: 6px 6px 0 0;
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.header h1 {
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: var(--bg-pink-color);
}

.buttton_header {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9999;
}

.nav {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 99px;
  outline: 0;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

.datetime {
  margin: 0 auto;
  font-size: 20px;
}

.week-container,
.day-container {
  display: flex;
  flex-wrap: wrap;
}

.week-cell,
.day-cell {
  flex: 0 0 calc(100% / 7);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45.7px;
  width: 40px;
}

.day-cell {
  opacity: 0.4;
  font-family: 'Darker Grotesque', sans-serif;
}

.day-cel--faded {
  opacity: 0.4;
}

.day-cel--today {
  border-radius: 99px;
  background-color: #1abc9c;
  color: #fff;
}

.day-cell--in-month {
  opacity: 1;
  color: #585858;
  font-weight: bold;
}

.active-date {
  background-color: #f1a09f;
  color: white;
  border-radius: 50%;
}

.namelocationOfWedding {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0 10px;
  gap: 3px;
  align-items: baseline;
}

.namelocationOfWedding img {
  transform: translate(0, 3px);
}

.addlocationOfWedding {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.interface {
  width: 100%;
  text-align: center;
}

.interface h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 30px 0;
  color: #585858;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.interface_title {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  text-align: center;
}

.interface_groom {
  color: #91ADA3;
}

.interface_bride {
  color: #F38723;
}

.family {
  text-align: center;
}

.family p {
  font-family: 'Darker Grotesque', sans-serif;
  margin-bottom: 5px;
  color: #585858;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.family p img {
  position: absolute;
  left: -30px;
}

/* .day-cell {
  &--faded {
    opacity: .4;
  }

  &--today {
    border-radius: 99px;
    background-color: #1abc9c;
    color: #fff;
  }
} */
/*  CONGRATULATION SECTION */
.href-call {
  display: flex;
  position: relative;
  justify-content: center;
  margin-left: 1.875rem;
}

.phone-container {
  position: relative;
}

.href-icon {
  position: absolute;
  animation: phoneCall 2s infinite;
  /* top: -30px; */
  left: -30px;
  width: 20px;
  /* transform: translateY(-50%); */
}

@keyframes phoneCall {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.btn-congrat-main,
.btn-congrat-second {
  border-radius: 1rem;
  color: white;
}

/* FOOTER SECTION */
.footer-container {
  position: relative;
}

.footer-icon-top {
  position: absolute;
  top: -2px;
  left: 0;
}

.footer-icon-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-qoute {
  position: absolute;
  bottom: 8rem;
}

.qoute-footer-top,
.qoute-footer-bottom {
  color: white;
  position: absolute;
  height: 50px;
}

.qoute-footer-top {
  top: -4.5rem;
  left: 0;
}

.qoute-footer-bottom {
  bottom: 0rem;
  right: 2rem;
}

.pt-96 {
  padding-top: 40rem;
}

.effect_light {
  position: relative;
  top: 0;
}

.footer-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  overflow: hidden;
}

.footer-logo img {
  object-fit: cover;
}

.letterPageShow.py-20 {
  padding-top: 2rem;
  padding-bottom: 25rem;
}

.loading_image {
  position: absolute;
  background: #08080885;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--white-color);
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



/* MODAL COMPONENT */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  visibility: hidden;
  width: 90vw;
  animation: opacity 0.3s linear;
}

.modal.active {
  visibility: visible;
  transform: all 0.3s linear;
  opacity: 1;
  top: 0;
}

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #333;
  opacity: 0.3;
  z-index: 5;
}

.modal-block {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100vw; */
  position: relative;
  height: 100vh;
  overflow: scroll;
}

.modal-block::-webkit-scrollbar {
  display: none;
}

.modal-content {
  background-color: white;
  z-index: 5;
  position: relative;
  margin: auto;
  max-width: 400px;
  text-align: center;
  overflow: auto;
}

.carousel .slider.animated{
  transition: none !important;
}

.slider.animated {
  transform: none;
}

.close-modal-icon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 10;
  width: 1.6rem;
  cursor: pointer;
}

.close-modal-icon svg {
  filter: brightness(0);
}

.img_upload_box .flex,
.img_upload_box .relative,
.img_upload_box .image-item img {
  width: 100%;
}

.renderContentModal {
  font-family: var(--quick-sand-font-family);
  padding-top: 5px;
}

.renderContentModal .head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.renderContentModal h2 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
}

.renderContentModal img {
  width: 30px;
}

.renderContentModal .contentModal {
  font-weight: 600;
  margin-bottom: 20px;
}

.renderContentModal .contentModal p {
  color: var(--black-color);
  margin-bottom: 0;
}

.index-image {
  position: absolute;
  top: 4px;
  left: 4px;
  color: white;
  z-index: 10;
  /* transform: translateX(-50%); */
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #333;
  opacity: 0.3;
  z-index: 5;
  animation: opacity 0.3s linear;
}

/* NAVIGATION */
.button-nav {
  top: 0.75rem;
  right: 0;
  z-index: 3;
}

.nav-content {
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  z-index: 9999;
  background-color: white;
  color: black;
  transform: translateX(100%);
  transition: all 0.3s linear;
}

.nav-content.active {
  transform: translateX(0);
}

.nav-list li {
  border-bottom: 1px solid rgba(145, 173, 163, 0.5);
}

.close-nav a {
  padding: 2rem 0.875rem;
  background-color: rgba(167, 189, 181, 0.5);
  width: 100%;
  height: 100%;
  display: block;
}

.navigate-nav a {
  padding: 0.875rem;
  display: block;
}

/* .overlay.active {
  animation: opacity 3s linear infinite;
} */

@keyframes opacity {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 0.3;
  }
}

/* IMAGE GALLERY */
.list_album_uploads .wrap_box_upload_image_child {
  margin-top: 0;
}

.gallery-image {
  /* height: 100vw; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* padding: 0 0.25rem; */
  margin: auto;
  width: 100%;
  /* height: 500px; */
  aspect-ratio: 3/4;
}

.gallery-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-container {
  display: flex;
  /* grid-template-columns: repeat(3, 1fr); */
  flex-wrap: wrap;
}

.gallery-img {
  /* width: 100%; */
  min-width: 20%;
  padding-top: 20%;
  position: relative;
  overflow: hidden;
}

.gallery-img .img-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.25rem;
}

.gallery-img .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.carousel-gallery-container {
  /* height: 80vh; */
  z-index: 12;
}

.casourel {
  /* height: 100vh; */
}

.preview-image {
  /* display: flex; */
  overflow-x: scroll;
}

.my-carousel {
  overflow: hidden;
}

/* MUSIC PLAYER */
.icon-music {
  width: 3rem;
  height: 3rem;
  color: var(--main-color);
}

.Createpage {
  background: var(--bg-gray-color-letter-page);
}

.section_wrap_type_ds {
  background-color: var(--gray-color-2);
  padding: 70px 0;
}

.section_wrap_type_ds .head h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 130%;
  color: var(--text-color-head);
  margin-bottom: 40px;
}

.slide-track {
  display: flex;
  gap: 30px;
  white-space: nowrap;
  scroll-behavior: smooth;
  /* overflow-x: scroll; */
  margin-bottom: 40px;
}

.slide-track::-webkit-scrollbar {
  height: 5px;
  width: 100%;
  background: var(--gray-color-3);
  border-radius: 10px;
}

.slide-track::-webkit-scrollbar-thumb {
  background: var(--pink-color-1);
  border-radius: 10px;
}

.slide-track .slide-item {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 23%;
  width: 100%;
  margin-bottom: 20px;
}

.slide-track .slide-item:hover {
  box-shadow: 0px 3px 9px 2px var(--bg-pink-color);
}

.slide-track .slide-item .box-image {
  background: var(--white-color);
}

.slide-track .slide-item .box-image img {
  width: 100%;
  object-fit: cover;
  border: 1px solid;
}

datalist option {
  display: none;
}

/* Hiển thị chỉ phần văn bản */
datalist option::before {
  content: attr(data-value);
}

.slide-track .slide-item .title span {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--pink-color-1);
  font-family: var(--quick-sand-font-family);
}

.slide-track .slide-item .title {
  margin: 10px 0;
}

.slide-track .slide-item .title h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: var(--text-color-head);
}

.slide .lazyload-wrapper {
  height: 100%;
}

.fullbgSize {
  background-size: cover !important;
}

.fill-icon {
  filter: opacity(1) brightness(1) contrast(0.5);
}

.bottom-button-click {
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
}

.containerCustormStyle div,
.containerCustormStyle h2 {
  color: var(--white-color);
}

.containerCustormStyle div {
  font-weight: 300;
  justify-content: center;
}

.containerCustormStyle .animate__fadeInRight {
  max-width: 400px;
}

.box_save_wish {
  padding-top: 50px;
  position: relative;
  display: flex;
  height: 100%;
  background: var(--bg-pink-blur);
}

.box_save_wish .grid_box_wish h3 {
  font-size: 34px;
  font-weight: 600;
  text-overflow: ellipsis;
  color: var(--text-color-head);
  letter-spacing: 0;
  max-width: 600px;
  margin-bottom: 30px;
}

.image_show_demo_wish {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 1500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.image_show_demo_wish img {
  margin: 0 auto;
  width: 100%;
}

.noItemTd {
  height: 80px;
}

.noItem {
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--black-color);
  position: absolute;
  left: 0;
  right: 0;
}

/*********************************Footer*****************************/

.footer {
  background-color: var(--bg-bold-green);
  padding: 50px 0;
  color: var(--white-color);
  clear: both;
}

.footer-letter {
  border-top: 2px solid var(--bg-tooltip-color);
}

.footer .component_ShowInf_Company h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
}

.footer .component_ShowInf_Company {
  font-family: var(--quick-sand-font-family);
}

.footer .component_ShowInf_Company .infomationDetails p {
  margin-bottom: 0;
}

.footer .component_ShowInf_Company .infomationDetails .contact {
  margin: 20px 0;
}

.footer .component_ShowInf_Company .infomationDetails .contact p {
  line-height: 2;
}

.footer .component_ShowInf_Company .infomationDetails .contact_phone {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.footer .component_ShowInf_Company .logo_footer img {
  width: 200px;
}

.divided-right {
  border-right: 1px solid var(--white-color);
}

.backgroundFromUser {
  background: var(--text-color-darkmode);
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
  box-shadow: 0px 13px 37px rgba(0, 0, 0, 0.7);
}

.backgroundFromUser .userFields_head {
  flex: 1 0;
  width: auto;
}

.backgroundFromUser .userFields_head h1 {
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  letter-spacing: -0.023em;
  color: var(--white-color);
  text-transform: uppercase;
}

.backgroundFromUser .fillDataForm {
  flex: 1 0;
  width: auto;
  margin-top: 20px;
}

.form-control {
  padding: 0 20px !important;
  background: var(--white-color) !important;
  color: var(--black-color) !important;
  font-family: var(--quick-sand-font-family);
}

.form-control::placeholder {
  color: var(--gray-input-color) !important;
}

.forgotPwd {
  text-align: right;
  margin: 10px 0;
  color: var(--white-color);
}

.otherLoginSocial {
  display: grid;
  gap: 20px;
}

.otherLoginSocial .titleOrther {
  text-align: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.otherLoginSocial .titleOrther span {
  color: var(--white-color);
  height: 24px;
  width: 100px;
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 600;
  background: var(--text-color-darkmode);
  display: block;
  overflow: hidden;
  z-index: 9;
  margin: auto;
  position: relative;
}

.otherLoginSocial .titleOrther::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  background: var(--white-color);
  top: 0;
  bottom: 0;
  margin: auto;
}

.footerFormData {
  background: var(--pink-color-1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 15px;
}

.footerFormData a {
  color: var(--white-color);
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  font-family: var(--quick-sand-font-family);
}

.main-form-user {
  display: grid;
  max-width: 550px;
  margin: auto;
}

.bg_form {
  background: var(--white-color);
  padding: 100px 15px;
}

.btn-back a {
  display: flex;
  align-items: center;
  color: var(--white-color);
  margin-bottom: 30px;
}

.btn-back a:hover svg {
  margin-left: -10px;
  transition: all 0.5s ease-out;
}

.btn-back a:hover span {
  margin-left: -10px;
  transition: all 0.5s ease-out;
  color: var(--pink-color-1);
}

.btn-back svg {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  transition: all 0.5s ease-out;
}

.btn-back span {
  font-family: var(--quick-sand-font-family);
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.023em;
  text-align: left;
  transition: all 0.5s ease-out;
}

.btn_box_create {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 45px;
  border: 1px dashed var(--bg-pink-color);
  border-radius: 15px;
  flex-direction: column;
  max-width: 400px;
  margin: 60px auto;
}

.btn_box_create.onlogged_show {
  max-width: 100%;
  border-width: 2px;
}

.btn_box_create img {
  max-width: 50px;
  margin-bottom: 20px;
}

.btn_box_create h2 {
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  text-align: center;
  color: var(--black-color);
}

.managertc {
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: var(--black-color);
  margin: 40px 0;
}

.wrap_customer_care {
  background: var(--white-color);
}

.faq_box_custormer {
  margin: 40px auto;
  max-width: 1430px;
}

.box_notice {
  background: var(--bg-pink-blur);
  padding: 40px 0;
}

.panel_colisape_group .accordion {
  border-bottom: 1px solid var(--gray-color-4);
  border-bottom: 1px solid var(--gray-color-4);
  background: #fff;
  margin-bottom: 20px;
  padding: 10px;
}

.faq_box_custormer .head h1,
.faq_box_custormer .head h2 {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  color: var(--black-color);
  margin-bottom: 40px;
}

.faq_box_custormer .collapse-child-title {
  justify-content: space-between !important;
  flex-flow: row-reverse !important;
}

.panel_colisape_description {
  font-family: var(--quick-sand-font-family);
  color: var(--black-color);
  font-weight: 500;
  padding: 0 15px 0;
  margin-top: 20px;
}

.panel_colisape_group .collapse-child-title {
  background: transparent;
  color: var(--black-color);
  font-size: 17px;
  text-transform: inherit;
  margin-bottom: 0;
}

.wrap_package_referralcode .man_inputStyle {
  margin-bottom: 5px;
}

.messageSuccess {
  color: var(--bg-tooltip-color);
  font-family: var(--quick-sand-font-family);
  font-size: 14px;
}


@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th {
  border-bottom: 2px solid var(--gray-color-2);
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--black-color);
  text-transform: uppercase;
}

table .formatnotColor {
  font-style: normal;
  font-weight: 600;
  font-family: var(--quick-sand-font-family);
  font-size: 17px;
}

table .payment {
  color: var(--red-color-mess-err);
}

table .complete {
  color: var(--primary-Blue-color-400);
}

table .autodelete,
table .onlydateplus {
  font-family: var(--quick-sand-font-family);
}

table .date {
  font-style: normal;
  font-weight: 600;
  font-family: var(--quick-sand-font-family);
  font-size: 18px;
  color: var(--black-color);
}

.navbar-toggler svg {
  width: 30px;
  height: 30px;
  color: var(--white-color);
}

.navbar_collapse .active {
  color: var(--color-hover) !important;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.wraps_title {
  font-size: 37px;
  font-weight: normal;
  font-family: 'Playfair Display', sans-serif;
}

.Notfound {
  position: fixed;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Notfound .number {
  background: #fff;
  position: relative;
  font: 900 30vmin 'Consolas';
  letter-spacing: 5vmin;
  text-shadow: 2px -1px 0 #000, 4px -2px 0 #0a0a0a, 6px -3px 0 #0f0f0f,
    8px -4px 0 #141414, 10px -5px 0 #1a1a1a, 12px -6px 0 #1f1f1f,
    14px -7px 0 #242424, 16px -8px 0 #292929;
}

.Notfound .number::before {
  background-color: #673ab7;
  background-image: radial-gradient(closest-side at 50% 50%,
      #ffc107 100%,
      rgba(0, 0, 0, 0)),
    radial-gradient(closest-side at 50% 50%, #e91e63 100%, rgba(0, 0, 0, 0));
  background-repeat: repeat-x;
  background-size: 40vmin 40vmin;
  background-position: -100vmin 20vmin, 100vmin -25vmin;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  -webkit-animation: moving 10s linear infinite both;
  animation: moving 10s linear infinite both;
  display: block;
  position: absolute;
  content: '';
}

.right-bar0box {
  justify-content: right !important;
}

.imgreview {
  max-width: unset !important;
}

.wrap_ds_review {
  background: var(--gray-color-2);
}

.wrap_ds_review .grid {
  align-items: center;
}

.box_details_ds {
  background-color: var(--white-color);
  padding: 36px 48px 20px;
  border: 2px solid var(--pink-color-1);
  border-radius: 20px;
  text-align: center;
  height: fit-content;
  max-width: 360px;
  margin: auto;
}

.box_details_ds .only {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 150%;
  color: var(--black-color);
}

.box_details_ds .price {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 150%;
  color: var(--black-color);
  margin-bottom: 0;
}

.box_details_ds p {
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: var(--black-color);
}

.list_details {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  flex-direction: column;
}

.list_details li {
  flex: 1 1;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0 20px;
}

.list_details li img {
  width: 30px;
}

.list_details li p {
  margin-bottom: 0;
}

.all_services {
  background: url('/src/assets/home-image/bg_bgsvtranparent.png') var(--pink-color-2) no-repeat;
  padding: 120px 15px;
  background-size: 1900px;
  background-position-y: bottom;
}

.all_services_types .head_hot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.all_services_types .head_hot h2 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 130%;
  text-align: center;
  color: var(--black-color);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.all_services_types .head_hot span {
  background: var(--pink-color-1);
  border-radius: 43px;
  padding: 5px;
  display: block;
  width: 100px;
  color: var(--white-color);
  text-transform: uppercase;
  font-family: var(--quick-sand-font-family);
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  animation: hot 1s ease-in;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: alternate;
}

.package_Box_sellect {
  overflow: hidden;
  height: fit-content;
  margin-top: 50px;
}

.image_box_left img {
  width: 100% !important;
}

.item_package_level {
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}

.item_package_level .header {
  padding: 24px 48px;
  gap: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  text-emphasis: none;
  text-align: center;
  color: var(--black-color);
  background: var(--white-color);
  display: block;
}

.item_package_level .header .header_price {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  color: var(--bg-pink-color);
}

.List_item_show {
  background: rgba(255, 255, 255, 0.5);
}

.List_item_show .item_single_line {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0 20px;
  color: var(--black-color);
  font-size: 20px;
  font-weight: 600;
  font-family: var(--quick-sand-font-family);
  letter-spacing: 1px;
  border-bottom: 1px solid var(--gray-color-2);
  padding: 36px 48px;
}

.List_item_show .item_single_line:last-child {
  border: unset;
}

.another_service_box {
  padding: 40px 0;
}

.choose_ser_another .box_head {
  text-align: center;
  margin-bottom: 40px;
}

.choose_ser_another .box_head h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  color: var(--black-color);
}

.choose_ser_another .box_head p {
  font-style: normal;
  font-size: 20px;
  color: var(--black-color);
  width: 60%;
  text-align: center;
  margin: 0 auto;
}

.list_item_services_another .item {
  border: 1px solid var(--bg-pink-color);
  background: var(--white-color);
  margin-bottom: 20px;
}

.list_item_services_another .item .item_content {
  text-align: center;
  padding: 20px 0;
}

.list_item_services_another .item .item_content h3 {
  font-weight: 600;
  font-size: 21px;
  margin-bottom: 25px;
}

.list_item_services_another .item .item_img img {
  width: 100%;
}

.property_type .property_desc {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: var(--black-color);
  font-family: var(--quick-sand-font-family);
}

.property_type .property_size {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: var(--black-color);
  font-family: var(--quick-sand-font-family);
  display: block;
  margin: 15px -15px;
}

.property_type .property_size span {
  padding: 0 15px;
}

.property_type .price p {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: var(--rbga--pink-bold);
  font-family: var(--quick-sand-font-family);
  display: flex;
  gap: 10px;
  align-items: center;
}

.property_type .price span {
  font-size: 17px;
}

.box_details_services {
  background: var(--bg-pink-blur);
  padding: 40px;
  margin: 50px 0;
}

.content_box_right {
  align-items: center;
  display: flex;
}

.content_box_right .content h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 150%;
  color: var(--black-color);
}

.content_box_right .content .descreption .block {
  margin-bottom: 25px;
}

.content_box_right .content .descreption .block .block_title {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.content_box_right .content .descreption .block .block_title span {
  background: var(--bg-pink-color);
  padding: 5px;
  border-radius: 10px;
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  color: var(--white-color);
}

.content_box_right .content .descreption .block .block_title p {
  margin-bottom: 0;
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--black-color);
}

.content_box_right .content .descreption .block .block_content ul {
  font-size: 18px;
  overflow: hidden;
  line-height: 28px;
  font-family: var(--quick-sand-font-family);
  color: var(--black-color);
}

.content_box_right .content .descreption .block .block_content ul li {
  display: flex;
  gap: 10px;
  align-items: baseline;
  padding-bottom: 5px;
}

.content_box_right .content .descreption .block .block_content ul li svg {
  font-size: 12px;
}

.Notfound .backlink {
  display: flex;
  margin-top: 50px;
  font-size: 20px;
  gap: 20px 20px;
  align-items: center;
  transition: all 1s ease-in-out;
}

.Notfound .backlink:hover {
  color: var(--pink-color-1);
  margin-left: -20px;
  transition: all 1s ease-in-out;
}

.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 9999999;
  top: 0;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
  color: var(--color-hover);
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@-webkit-keyframes moving {
  to {
    background-position: 100vmin 20vmin, -100vmin -25vmin;
  }
}

@keyframes moving {
  to {
    background-position: 100vmin 20vmin, -100vmin -25vmin;
  }
}

@-webkit-keyframes hot {
  to {
    box-shadow: 0 0 10px var(--color-red),
      0 0 20px var(--color-red),
      0 0 30px var(--color-red);
    background-color: var(--color-red);
  }
}

@keyframes hot {
  to {
    box-shadow: 0 0 10px var(--color-red),
      0 0 20px var(--color-red),
      0 0 30px var(--color-red);
    background-color: var(--color-red);
  }
}

.text {
  font: 400 5vmin 'Courgette';
}

.text span {
  font-size: 10vmin;
}

/* Response letter section */
.side-choose:hover {
  /* transform: scale(1.1); */
  cursor: pointer;
}

/* hide arrow input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-letter {
  -moz-appearance: textfield;
}

input[type='radio'] {
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.input-count-num {
  text-align: center;
  font-size: 2rem;
}

.input-letter {
  height: 44px;
}

.input-letter:focus,
.input-letter:focus-visible {
  border: 2px solid var(--letter-main-color);
  outline: none;
}

/* Congrats detail page */
.congrats-wrapper {
  color: white;
  background-color: var(--letter-main-color);
}

.congrats-wrapper svg path {
  fill: white !important;
  color: white;
}

/* LETTER ENVELOP */

/* ----------------------- */

.root-remove {
  display: flex;
  user-select: none;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.root-remove .pointer svg,
.image-item .pointer svg {
  background: #00000042;
  border-radius: 15px;
}

.dragged {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.pointer {
  cursor: pointer;
}

img {
  -webkit-user-drag: none;
}

.ImgUploadIcon {
  margin: 20px 0;
}

.ImgUploadIcon svg {
  font-size: 1em;
  width: calc(80%);
  margin: auto;
}

.image-item .absolute {
  top: 5px;
  right: 5px;
}

.image-item img {
  border-radius: 20px;
  object-fit: cover;
}

.wrap_imageUploading {
  background-color: var(--gray-color-4);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 0;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  border-radius: 20px;
}

.add_image_uploading {
  font-family: var(--quick-sand-font-family);
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary-Blue-color-400);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

.desc_image_uploading {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-family: var(--quick-sand-font-family);
}

.wrap_box_upload_image_child {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 20px 0;
  overflow: hidden;
}

.uploading_single_img_group h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 150%;
  font-family: var(--quick-sand-font-family);
  color: var(--black-color);
  text-transform: inherit;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.wrapper_information_wedding {
  margin: 0 0;
}

.tooltip_effect_bg {
  font-family: var(--quick-sand-font-family);
  font-style: italic;
  color: var(--black-color);
}

.title_comp_wed {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title_comp_wed h2 {
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: var(--color-hover);
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.title_comp_wed h2 div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title_comp_wed_add_cus h2 {
  font-size: 40px;
  margin-bottom: 0;
}

.title_comp_wed .divided {
  width: 95%;
  height: 1px;
  background: var(--color-hover);
  position: absolute;
  bottom: 0;
}

.RadioButton {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.RadioButton label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: var(--quick-sand-font-family);
  color: var(--black-color);
}

.Createpage .title {
  line-height: 20px;
  font-family: var(--quick-sand-font-family);
  color: var(--black-color);
  font-size: 20px;
  font-weight: 600;
}

.effect_image_options {
  display: flex;
  gap: 35px;
  margin: 30px 0;
  justify-content: center;
}

.header_editpage {
  padding: 20px;
  z-index: 2;
  /* backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */
  margin: auto;
  background: var(--white-color);
}

.btn_group_r {
  display: flex;
  gap: 30px;
}

.my_input_form_data_group {
  background: var(--white-color);
  max-width: 75%;
  margin: 0 auto 0;
  padding-bottom: 40px;
  padding-top: 40px;
  box-shadow: 0px 13px 37px rgba(0, 0, 0, 0.21);
}

.my_input_form_data_group .container {
  max-width: 971px;
}

.input_fields_control {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  position: relative;
  margin-bottom: 30px;
}

.input_fields_control .place_title_input label {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  font-family: var(--quick-sand-font-family);
  color: var(--black-color);
  min-width: 68px;
  display: block;
}

.inputStyle {
  width: 15px !important;
}

.group_input_control {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 40px;
}

.group_input_control .col-width-input-left {
  flex: 1;
}

.group_input_control .col-width-input-left .man_inputStyle,
.group_input_control .col-width-input-left .form_sellect_control {
  max-width: 100%;
}

.group_input_control .checkbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: 10px;
  align-items: center;
}

.item_field_single {
  flex-basis: 251px;
  flex-grow: revert;
}

.man_inputStyle {
  max-width: 251px;
}

.man_inputStyle label {
  font-family: var(--quick-sand-font-family);
  font-weight: 500;
}

.input_create_half {
  width: 100%;
}

.item_field_single .sellect_option {
  display: flex;
  flex-direction: column;
}

.item_field_single .select_phone_code {
  max-width: 95px;
  width: 95px;
}

.item_field_single .sellect_option label {
  margin-bottom: 5px;
  font-family: var(--quick-sand-font-family);
  font-weight: 500;
}

.form_sellect_control {
  width: 100%;
  background: var(--color-input);
  height: 40px;
  padding: 10px 15px;
  color: var(--black-color);
  border-radius: 5px;
  border: none;
  font-family: var(--quick-sand-font-family);
}

.select_form_search,
.select_province,
.select_district,
.select_wardt {
  padding: 0;
  background: none;
}

.css-13cymwt-control {
  background-color: var(--color-input) !important;
}

.form_sellect_control option {
  padding: 5px;
}

.coppyMaps {
  font-family: var(--quick-sand-font-family);
}

.select_code {
  max-width: 70px;
}

.select_phone_code {
  max-width: 95px;
}

.enable_show_deadman {
  font-family: var(--quick-sand-font-family);
  display: flex;
  gap: 30px;
  margin-top: 40px;
}

.enable_show_deadman.active {
  opacity: 1;
  pointer-events: fill;
}

.enable_show_deadman.deactive {
  opacity: 0.4;
  pointer-events: none;
}

.enable_show_deadman .label_left {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: var(--gray-Dark-Dark-Mode-50);
}

.enable_show_deadman .customize_radio {
  margin-bottom: 10px;
}

.chrysanthemumIcon {
  display: flex;
  gap: 5px;
}

.chrysanthemumIcon img {
  width: 21px;
}

.section_choose_template .head_template h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  font-family: var(--quick-sand-font-family);
  color: var(--color-hover);
}

.group_textarea_control,
.Select_invitation_template {
  width: 100%;
}

.group_radio_choose_template .customize_radio {
  margin-bottom: 20px;
}

.group_radio_choose_template label {
  background: var(--color-input);
  padding: 10px;
  text-align: left;
  width: 100%;
}

.float_display {
  float: right;
  max-width: 833px;
  width: 100%;
  clear: both;
}

.ic_ceateoutline {
  width: 50px;
  margin-bottom: 10px;
  cursor: pointer;
}

.ic_ceateoutline img {
  width: 100%;
}

.double_input_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
}

.double_input_row .half_row_hor_input {
  width: 100%;
}

.double_input_row .half_row_hor_input .man_inputStyle {
  max-width: 100%;
}

.inforBank_one_per {
  display: flex;
  align-items: center;
  gap: 30px;
}

.inforBank_one_per .man_inputStyle {
  max-width: 100%;
}

.inforBank_one_per .three_input_col {
  flex: 1;
}

.fullwidth_input_colum {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.fullwidth_input_colum .man_inputStyle {
  width: 100%;
  max-width: 100%;
}

.checkbox_inline_colum .man_inputStyle {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  margin: 10px 0;
}

.panel.multi-collapse {
  display: block;
}

.collapse-child-title {
  background: var(--panel-color);
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px 10px;
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--white-color);
  cursor: pointer;
  gap: 30px;
  position: relative;
}

.collapse-child-title.panel_icon_style {
  gap: 10px;
}

.collapse-child-title.panel_icon_style .right_title_panel {
  gap: 10px;
}

.collapse-child-title .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.collapse-child-title .no_fields {
  position: absolute;
  right: 10px;
  font-size: 24px;
  text-transform: initial;
}

.collapse-child-title::-moz-selection {
  background: transparent;
}

.collapse-child-title::selection {
  background: transparent;
}

.collapse-child.isclose {
  visibility: hidden;
  height: 0;
  display: none;
}

.collapse-child.ishow {
  visibility: visible;
  height: fit-content;
  display: block;
  margin-bottom: 20px;
}

.album_list_thumb_wedding {
  margin: 20px 0;
  display: block;
}

input:disabled {
  cursor: not-allowed;
}

.album_list_thumb_wedding .notifi {
  list-style-type: disc;
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-left: 20px;
  color: var(--black-color);
}

.album_list_thumb_wedding .notifi li {
  padding: 5px 0;
}

.list_album_uploads .root-remove {
  justify-content: start;
}

.list_album_uploads .uploading_single_img_group img {
  max-width: 150px;
  width: 150px !important;
  object-fit: cover;
}

.list_album_uploads .wrap_imageUploading {
  width: 150px;
  flex: unset;
  padding: 0;
}

.list_album_uploads .wrap_imageUploading .ImgUploadIcon {
  margin: 0;
}

.list_album_uploads .wrap_imageUploading .ImgUploadIcon svg {
  width: calc(50%);
}

.list_album_uploads .wrap_imageUploading .add_image_uploading {
  font-size: 13px;
}

.sec_group_panel_collape {
  clear: both;
  margin-top: 20px;
}

.right_title_panel {
  display: flex;
  align-items: center;
  gap: 20px;
}

.right_title_panel span {
  color: var(--gray-color);
  font-weight: normal;
}

.box_notice .faq_box_custormer .accordion.ishow {
  border-radius: 10px;
  border: 1px solid var(--bg-pink-color);
}

.category_name_group h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 150%;
  color: var(--black-color);
  padding: 0 15px;
}

.section_video_NFT {
  background: var(--bg-pink-blur);
  padding: 50px 0;
  position: relative;
  min-height: 750px;
  display: flex;
  align-items: center;
}

.section_video_NFT .tab-content .tab-list {
  border-bottom: 1px solid var(--bg-pink-color);
  margin-bottom: 20px;
  display: flex;
  gap: 40px;
}

.section_video_NFT .tab-content .tab-list li.tab {
  color: var(--black-color);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  font-family: var(--quick-sand-font-family);
  padding-bottom: 10px;
  cursor: pointer;
}

.section_video_NFT .tab-content .tab-list li.tab.active {
  border-bottom: 2px solid var(--bg-pink-color);
  color: var(--bg-pink-color);
  font-weight: 700;
}

.NFT_parent_bg {
  border: 3px solid var(--bg-pink-color);
  padding: 10px;
}

.NFT_child_bg {
  border: 1.5px solid var(--bg-pink-color);
  padding: 10px;
}

.tab_index_container h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 130%;
  color: var(--black-color);
  margin: 20px 0;
}

.tab_index_container strong {
  margin: 15px 0;
  display: block;
  font-family: var(--quick-sand-font-family);
  font-weight: 600;
}

.tab_index_container ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 30px;
}

.tab_index_container ul li {
  padding: 5px 0;
  color: var(--black-color);
  font-family: var(--quick-sand-font-family);
  font-weight: 600;
}

.box_abs_image_video {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 350px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.box_abs_image_video .frame_video_box {
  position: relative;
}

.box_abs_image_video .frame_video_box video {
  position: absolute;
  height: 86%;
  width: 83%;
  top: 65px;
  right: 30px;
  background: #fff;
  border-radius: 0 0 35px 35px;
}

.disable {
  pointer-events: none;
}

.disable::placeholder {
  color: var(--black-color) !important;
}

.section_banking_groom {
  margin-top: 20px;
  padding-bottom: 20px;
}

.section_banking_groom h2,
.section_banking_Bride h2 {
  font-family: var(--quick-sand-font-family);
  font-size: 25px;
  font-weight: 600;
  color: var(--black-color);
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom_display_sec_radio_music {
  display: flex;
  align-items: end;
  gap: 20px;
  margin-bottom: 40px;
}

.custom_display_sec_radio_music .group_radio_choose_template .customize_radio {
  margin-bottom: 0;
  padding-top: 15px;
}

.custom_display_sec_radio_music .group_radio_choose_template .SelectInvitationTemplate_map:first-child .customize_radio {
  padding-top: 0;
}

.custom_display_sec_radio_music .group_radio_choose_template label {
  background: none;
  padding: 0;
}

.custom_display_sec_radio_music .multimusic_wrap {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 14px;
}

.custom_display_sec_radio_music .head_template {
  display: none;
}

.button_span_music {
  display: flex;
  gap: 20px;
  font-family: var(--quick-sand-font-family);
}

.details_attend {
  background: var(--gray-color-2);
  padding: 30px;
  margin-bottom: 15px;
  color: var(--black-color);
  font-family: var(--quick-sand-font-family);
}

.custom_style_radio .group_radio_choose_template {
  display: flex;
  justify-content: space-between;
}

.custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map label {
  display: none;
}

.custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map input:is([type='checkbox'], [type='radio']) {
  -webkit-appearance: none;
  width: 133px;
  height: 105px;
  border: 2px solid var(--gray-color-4);
  border-radius: 0.3em;
  transition: background-color 300ms, border-color 300ms;
  background-repeat: no-repeat;
}

.option_invitation_style .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:first-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcDefault.png');
  background-position-x: center;
  background-position-y: center;
}

.option_invitation_style .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(2) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcRoyal.png');
  background-position-x: center;
  background-position-y: center;
}

.option_invitation_style .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(3) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcElegant.png');
  background-position-x: center;
  background-position-y: center;
}

.option_title .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:first-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcPacifico.png');
}

.option_title .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(2) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcViber.png');
}

.option_title .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(3) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcYeseva.png');
}

.option_title .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(4) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcLoster.png');
}

.option_title .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:last-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcPlayfair.png');
}

.option_content .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:first-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcInter.png');
}

.option_content .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(2) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcRoboto.png');
}

.option_content .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(3) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcOpensans.png');
}

.option_content .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(4) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcMonterrat.png');
}

.option_content .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:last-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcNatosans.png');
}

.option_type_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:first-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcNone.png');
}

.option_type_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(2) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcHoa.png');
}

.option_type_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(3) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcLa.png');
}

.option_type_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(4) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcTuyet.png');
}

.option_type_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:last-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/Ichoavan.png');
}

.option_color_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:first-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcTrang.png');
}

.option_color_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(2) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcXam.png');
}

.option_color_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(3) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcHong.png');
}

.option_color_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(4) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcXanh.png');
}

.option_color_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:last-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/Icvang.png');
}

.option_effect_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:first-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcNone.png') #F2F2F2;
}

.option_effect_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(2) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcHoadao.png') #F2F2F2;
}

.option_effect_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(3) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcLavang.png') #F2F2F2;
}

.option_effect_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:nth-child(4) input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcBongtuyet.png') #F2F2F2;
}

.option_effect_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map:last-child input:is([type='checkbox'], [type='radio']) {
  background: url('../src/assets/home-image/IcKimtuyen.png') #F2F2F2;
}

.option_effect_bg .custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map input:is([type='checkbox'], [type='radio']) {
  background-size: contain !important;
}

.custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map input:is([type='checkbox'], [type='radio']):hover {
  border: 2px solid var(--primary-Blue-color-400);
}

.custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map input:is([type='checkbox'], [type='radio']):focus {
  outline: 2px solid var(--primary-Blue-color-400);
  outline-offset: 2px;
}

.custom_style_radio .group_radio_choose_template .SelectInvitationTemplate_map input:is([type='checkbox'], [type='radio']):checked {
  border: 2px solid var(--primary-Blue-color-400);
  filter: brightness(1);
}

.option_type_container .option_title_head h5 {
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: var(--black-color);
}

.algin_item-center {
  align-items: center;
}

.wrap_package_product .form_sellect_control {
  max-width: 100%;
}

.another_item>div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.clear {
  clear: both;
}

.savesetting_btn {
  clear: both;
  position: fixed;
  bottom: 0;
  width: 75%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.savesetting_btn button {
  border-radius: 0;
}

.rounded {
  border-radius: unset;
}

.wrap_flop_note_using {
  padding-bottom: 40px;
  padding-top: 10px;
}

.wrap_flop_note_using .box_note_using {
  padding: 20px 0;
  background: var(--bg-pink-blur);
  color: var(--black-color);
  max-width: 100%;
  font-family: var(--quick-sand-font-family);
  margin: 0 auto;
  border-radius: 5px;
}

.wrap_flop_note_using .box_note_using ul {
  list-style-type: disc;
  margin-left: 50px;
}

.wrap_flop_note_using .box_note_using ul li {
  padding: 5px 0;
}

.double_input_row span {
  font-family: var(--quick-sand-font-family);
}

.sec_video {
  background: var(--bg-pink-blur);
  padding: 10px 0 100px;
}

.sec_video iframe {
  margin: auto;
  border-radius: 15px;
}

.box_support_guest {
  border: 2px solid var(--bg-pink-color);
  margin: 0 auto 50px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0 0 0;
  max-width: 1100px;
}

.box_support_guest .all_ready {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: center;
  font-size: 48px;
  margin-bottom: 20px;
}

.box_support_guest .all_ready span {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 150%;
  color: var(--bg-pink-color);
}

.box_support_guest .all_ready svg {
  fill: var(--bg-pink-color);
}

.box_support_guest .timetowork {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: var(--quick-sand-font-family);
  font-weight: 500;
}

.box_support_guest .timetowork p {
  margin-bottom: 0;
  font-size: 18px;
}

.box_support_guest .bottom_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
  background: var(--pink--bold);
  padding: 30px 0;
}

.menu_bar_fix_top {
  background-color: var(--white-color);
  min-width: 160px;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px var(--gray-color);
  max-width: max-content;
  width: 100%;
  margin: auto;
}

.menu_bar_fix_top ul {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.menu_bar_fix_top ul li {
  padding: 0 40px;
  text-align: center;
  font-family: var(--quick-sand-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  color: var(--black-color);
  border-left: 1px solid var(--gray-color);
}

.menu_bar_fix_top ul li:first-child {
  border: none;
}

.menu_bar_fix_top ul li:hover {
  color: var(--color-hover);
}

.menu_bar_fix_top ul li:last-child {
  border-bottom: none;
}

.form_confirm_info .header {
  justify-content: center;
}

.form_confirm_info .header h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-family: var(--quick-sand-font-family);
  color: var(--color-hover);
  text-align: center;
  margin-bottom: 25px;
}

.body_form .wrap_form h4,
.body_info_price .bode_info_head h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  font-family: var(--quick-sand-font-family);
  color: var(--color-hover);
  text-align: left;
  text-transform: inherit;
}

.body_form .wrap_form .address_province_ {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.body_info_list_product .package_box {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
}

.body_info_list_product .package_box h5 {
  text-transform: inherit;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 0;
  text-align: left;
  font-weight: 700;
  color: var(--black-color);
}

.body_info_list_product .package_box p {
  margin-bottom: 0;
  text-align: left;
}

.total_price {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--black-color);
  padding: 20px 0 0;
  align-items: center;
}

.total_price h5 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  color: var(--black-color);
}

.total_price span {
  font-weight: 700;
  color: var(--black-color);
  font-size: 24px;
}

.free14day {
  text-align: right;
  color: var(--black-color);
  font-weight: 700;
  margin-bottom: 15px;
}

.bottom_form_btn_success .des_pay_services {
  color: var(--black-color);
  font-weight: 700;
  margin-bottom: 0;
}

.bottom_form_btn_success .contact {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-hover);
}

.show_type_display {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-size: 19px;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  position: relative;
}

.show_type_display img {
  position: absolute;
  left: 0;
}

.name_show {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  margin-bottom: 0;
}

.carousel .slider-wrapper {
  padding-bottom: 20px;
}

.carousel .control-dots .dot {
  background: #000 !important;
}

.dashed-top-bottom {
  font-size: 16px;
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
  font-weight: 600;
}

.dashed-top-bottom:after {
  content: '';
  border-bottom: 1px dashed rgba(243, 135, 35, 0.4);
  position: absolute;
  width: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.title_tooltipInfo {
  font-weight: 600;
  color: var(--orange-color);
}

.bank_info_show {
  display: block;
  position: relative;
}

.bank_info_show .content_info {
  display: block;
  background: var(--gray-color-2);
  border-radius: 10px;
  padding: 10px;
  font-family: var(--quick-sand-font-family);
  margin-bottom: 15px;
  margin-top: 10px;
}

.bank_info_show .content_info span {
  font-size: 13px;
}

.bank_info_show .content_info p {
  font-size: 15px;
  font-weight: 600;
}

.banking_happy_invite .carousel .slide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.banking_happy_invite .carousel .slide.selected {
  opacity: 1;
}

.checkbox {
  display: none;
}

.checkbox-label .icon_toogle {
  display: none;
}

.ref_footer_style_Homepage {
  background-color: var(--bg-bold-green);
  height: 100vh;
}

.bock_content_modal {
  display: flex;
  gap: 30px;
  color: var(--black-color);
}

.social_login {
  justify-content: center;
  font-family: var(--quick-sand-font-family) !important;
  color: var(--black-color) !important;
  font-size: 16px !important;
}

.block_step {
  border: 1px solid var(--gray-color-2);
  width: 100%;
  max-width: 400px;
  padding: 20px;
}

.content_step img {
  width: 100%;
  margin: 15px auto;
}

.content_step .infor {
  color: var(--bg-pink-color);
}

.content_step .infor span {
  display: block;
}

.content_step .warn {
  color: var(--bg-pink-color);
  font-size: 25px;
  margin-top: 10px;
  font-weight: 500;
}

.content_step .demo {
  background: var(--bg-pink-color);
  border-radius: 30px;
  color: var(--white-color);
  padding: 10px 15px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
}

.content_step .demo p {
  margin-bottom: 0;
}

.renderContentModal .chuy {
  color: var(--black-color);
  font-weight: 600;
  font-size: 16px;
  margin: 15px 0;
}

.renderContentModal .chuy strong {
  color: var(--bg-pink-color);
}

.modal_box_success_payment {
  color: var(--black-color);
}

.modal_box_success_payment img {
  width: 80px;
  margin: 0 auto 20px;
}

.modal_box_success_payment h3 {
  color: var(--bg-pink-color);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 20px;
}

.modal_box_success_payment p {
  font-weight: 500;
  font-size: 17px;
}

.modal_box_success_payment .br {
  margin: 30px auto;
  height: 0.5px;
  background: var(--bg-pink-color);
  width: 80%;
}

.modal_box_success_payment .contact_now h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--black-color);
}

.modal_box_success_payment .contact_now p {
  font-weight: 500;
  font-size: 14px;
  color: var(--black-color);
}

.pagination {
  display: block;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.navigation button {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--bg-pink-color);
  padding: 15px;
  font-family: var(--quick-sand-font-family);
  font-size: 14px;
  cursor: pointer;
}

.slider_cmt .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banking_happy_invite .text-left img {
  height: 300px;
  object-fit: contain;
}

.banking_happy_invite .thumb img {
  height: 70px;
  object-fit: cover;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
}

.head_option {
  display: flex;
  border-bottom: 1px solid var(--color-bold-pink);
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.body_form .wrap_form .head_option h4 {
  color: var(--color-bold-pink);
  margin-bottom: 0;
  text-transform: uppercase;
}

.group_options {
  display: flex;
  margin: 20px 0 40px;
  gap: 20px;
}

.group_options .group__items {
  padding: 15px 30px;
  border-radius: 10px;
  border: 1px solid var(--black-color);
}

.group_options .group__items.active {
  border-color: var(--bg-pink-color);
  border-width: 2px;
}

.group_options .group__items.active input[type='radio'],
.sec_group_panel_checkbox .single_hor_input input[type='checkbox'] {
  accent-color: var(--color-bold-pink);
}

.sec_group_panel_checkbox {
  display: flex;
  gap: 30px;
  margin: 20px 0 40px;
}

.sec_group_panel_checkbox .single_hor_input {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid var(--black-color);
}

.sec_group_panel_checkbox .single_hor_input.active {
  border-color: var(--bg-pink-color);
  border-width: 2px;
}

.sec_group_panel_checkbox .man_inputStyle {
  text-align: center;
  margin: 0;
}

.sec_group_panel_checkbox .man_inputStyle .price {
  color: var(--bg-pink-color);
  font-weight: 600;
  font-size: 16px;
  display: block;
}

.bg-invitation {
  min-height: 740px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitation_title {
  color: #585858;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.invitation_desp {
  width: 60%;
  margin: auto;
}

.outstanding_box {
  padding-bottom: 20px;
}

.title_outstanding {
  color: #F38723;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.justify-space-between {
  justify-content: space-around;
}

.wedding-vow {
  height: 700px;
  position: relative;
}

.wedding-vow::after,
.wedding-vow::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 33px;
}

.wedding-vow::before {
  background: url('../src/assets/home-image/top.png') no-repeat;
  background-size: cover;
  top: -5px;
}

.wedding-vow::after {
  background: url('../src/assets/home-image/down.png') no-repeat;
  background-size: cover;
  bottom: 0;
}

.background_couple {
  display: flex;
  height: 100%;
  background-size: cover;
}

.content_wedding_vow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
}

.content_wedding_vow::after {
  content: '';
  background: linear-gradient(0deg, #FFF -3.9%, rgba(255, 255, 255, 0.70) 49.83%, rgba(255, 255, 255, 0.00) 99.63%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.content_wedding_vow .quote {
  height: 33px;
  width: 97%;
}

.content_wedding_vow .quote_top {
  background: url('../src/assets/home-image/quote_top.png') no-repeat;
  background-size: contain;
}

.content_wedding_vow .quote_bottom {
  background: url('../src/assets/home-image/quote_bot.png') no-repeat;
  background-size: contain;
  width: 40px;
  margin-right: 10px;
}

.content_wedding_vow .content {
  margin: 10px;
  padding: 0 77px;
  line-height: 200%;
  color: #585858;
  font-style: italic;
  position: relative;
  z-index: 999;
  font-size: 18px;
}

.box-infor button {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.quill {
  background: var(--color-input);
}

.ql-container.ql-snow {
  height: 200px;
  background: var(--color-input);
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }

  input[type="date"]+div>svg,
  input[type="time"]+div>svg {
    display: none;
    -webkit-appearance: none;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1210px;
  }
}

@media (min-width: 1536px) {
  .container {
    width: 1400px;
  }
}

@media (min-width: 768px) {
  .image_show_demo_wish img {
    width: 1000px;
  }

}

@media (max-width: 991px) {

  input[type="time"],
  input[type="date"] {
    width: 100%;
    display: flex;
    min-width: 95%;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="time"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  .header_editpage,
  .my_input_form_data_group {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .my_input_form_data_group {
    padding-top: 0;
  }

  .uploading_single_img_group {
    margin-bottom: 20px;
  }

  .group_input_control {
    display: block;
  }

  .group_input_control .checkbox {
    margin: 15px 0;
  }

  .body_form .wrap_form .address_province_ {
    display: block;
    margin-bottom: 0;
  }

  .header {
    flex-direction: column;
    gap: 10px;
  }

  .header h1 {
    font-size: 20px;
    text-align: center;
  }

  .buttton_header {
    position: relative;
  }

  .collapse-child-title {
    font-size: 18px;
    gap: 10px;
  }

  .collapse-child-title .no_fields {
    font-size: 9px;
  }

  .slide-container img {
    height: 100vh;
  }

  .savesetting_btn {
    width: 100%;
  }

  .title_comp_wed h2 {
    font-size: 18px;
  }

  .form_sellect_control {
    margin-bottom: 10px;
  }

  .all_services {
    padding: 50px 0;
  }

  .List_item_show .item_single_line {
    padding: 26px 8px;
  }

  .dotted_scroll {
    display: none;
  }

  .main_tit .tit_type2 {
    font-size: 35px;
  }

  .navbar-toggler svg {
    fill: #000;
  }

  .wrapbox_image_pc {
    align-items: center;
  }

  div[name='section3'] .wrapbox_image_pc .head h3 {
    margin-bottom: 250px;
  }

  .image_single_pc {
    bottom: 100px;
    max-width: 100%;
  }

  .box_3phone_wrap_image {
    max-width: 100%;
    justify-content: center;
  }

  .box_3phone_wrap_image img {
    margin-bottom: 200px;
    width: 1000px;
    max-width: unset;
    object-fit: none;
    overflow: inherit;
  }

  .image_show_demo_wish {
    justify-content: start;
    margin-left: 0;
  }

  .image_show_demo_wish img {
    width: 1500px;
    max-width: unset;
  }

  .pt-96 {
    padding-top: 20rem;
    font-size: 18px;
  }

  .effect_light {
    top: 145px;
    padding-bottom: 3rem;
  }

  .footer .component_ShowInf_Company {
    margin-bottom: 30px;
  }

  .view_experience {
    display: none;
  }

  .menu_bar_fix_top {
    display: none;
  }

  .box_support_guest .all_ready span {
    font-size: 22px;
  }

  .box_support_guest .all_ready svg {
    font-size: 25px;
  }

  .box_support_guest {
    margin: 20px auto;
  }

  .sec_video {
    padding: 50px 0;
  }

  .respon-table {
    display: block;
    overflow: auto;
  }

  .respon-table button {
    width: 100% !important;
  }

  .respon-table tbody td {
    min-width: 180px;
  }

  .choose_ser_another .box_head h2 {
    font-size: 25px;
  }

  .choose_ser_another .box_head p {
    width: 100%;
  }

  .title_tooltipInfo,
  .show_type_display {
    font-size: 14px;
  }

  .dashed-top-bottom {
    font-size: 14px;
  }

  .show_type_display img {
    position: absolute;
    top: -15px;
  }

  .text-2xl {
    font-size: 1.1rem;
  }

  .name_show {
    font-size: 17px;
  }

  .namelocationOfWedding img {
    transform: translate(0, 3px);
  }
}

@media (max-width: 1299px) {
  .my_input_form_data_group {
    max-width: fit-content;
  }

  .header_editpage {
    width: 100%;
  }
}

@media (max-width: 991px) {

  .header_editpage,
  .my_input_form_data_group {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .my_input_form_data_group {
    padding-top: 0;
  }

  .uploading_single_img_group {
    margin-bottom: 20px;
  }

  .group_input_control {
    display: block;
  }

  .group_input_control .checkbox {
    margin: 15px 0;
  }

  .body_form .wrap_form .address_province_ {
    display: block;
    margin-bottom: 0;
  }

  .header {
    flex-direction: column;
    gap: 10px;
  }

  .header h1 {
    font-size: 20px;
    text-align: center;
  }

  .buttton_header {
    position: relative;
  }

  .root-remove {
    flex-wrap: wrap;
  }

  .collapse-child-title {
    font-size: 18px;
    gap: 10px;
  }

  .collapse-child-title .no_fields {
    font-size: 9px;
  }

  .savesetting_btn {
    width: 100%;
  }

  .title_comp_wed h2 {
    font-size: 18px;
  }

  .form_sellect_control {
    margin-bottom: 10px;
  }

  .all_services {
    padding: 50px 0;
  }

  .List_item_show .item_single_line {
    padding: 26px 8px;
  }

  .dotted_scroll {
    display: none;
  }

  .main_tit .tit_type2 {
    font-size: 35px;
  }

  .navbar-toggler svg {
    fill: #000;
  }

  .wrapbox_image_pc {
    align-items: center;
  }

  div[name='section3'] .wrapbox_image_pc .head h3 {
    margin-bottom: 250px;
  }

  .image_single_pc {
    bottom: 100px;
    max-width: 100%;
  }

  .box_3phone_wrap_image {
    max-width: 100%;
    justify-content: center;
  }

  .box_3phone_wrap_image img {
    margin-bottom: 200px;
    width: 1000px;
    max-width: unset;
    object-fit: none;
    overflow: inherit;
  }

  .image_show_demo_wish {
    justify-content: start;
    margin-left: 0;
  }

  .image_show_demo_wish img {
    width: 1500px;
    max-width: unset;
  }

  .footer .component_ShowInf_Company {
    margin-bottom: 30px;
  }

  .view_experience {
    display: none;
  }

  .menu_bar_fix_top {
    display: none;
  }

  .box_support_guest .all_ready span {
    font-size: 22px;
  }

  .box_support_guest .all_ready svg {
    font-size: 25px;
  }

  .box_support_guest {
    margin: 20px auto;
  }

  .sec_video {
    padding: 50px 0;
  }

  .respon-table {
    display: block;
    overflow: auto;
  }

  .respon-table button {
    width: 100% !important;
  }

  .respon-table tbody td {
    min-width: 180px;
  }

  .choose_ser_another .box_head h2 {
    font-size: 25px;
  }

  .choose_ser_another .box_head p {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .text_block_line_mb {
    display: block;
    text-align: center;
    padding: 0;
    font-size: 23px;
  }

  .py-20 {
    padding-top: 3rem;
  }

  .text-9xl {
    font-size: 6rem;
  }

  .positions_moblie {
    top: 75px !important;
  }

  .invitation_desp {
    width: 83%;
  }

  .bg-invitation {
    min-height: 580px;
  }

  .title_outstanding {
    padding: 0 50px;
  }

  .outstanding_box .px-20 {
    padding: 0 50px;
  }

  .section_wrap_type_ds .head h2 {
    font-size: 25px;
  }

  .choosetypeblock_box .animate__animated {
    width: 100%;
  }

  .slide-track {
    overflow-x: scroll;
  }

  .slide-track .slide-item {
    max-width: 70%;
  }

  .section_wrap_type_ds {
    padding: 30px 15px;
    width: 100%;
  }

  .footer {
    padding: 20px 15px;
  }

  .footer .component_ShowInf_Company {
    margin-bottom: 15px;
  }

  .footer .component_ShowInf_Company h2 {
    margin-bottom: 0;
  }

  .divided-right {
    border: none;
  }

  .wrapper_box_create .container {
    padding: 0 15px;
  }

  .bg_form {
    padding: 15px 15px;
  }

  .all_services_types .head_hot h2 {
    font-size: 25px;
  }

  .all_services_types .head_hot span {
    width: 70px;
    font-size: 16px;
  }

  .wrap_ds_review {
    padding: 40px 0;
  }

  .box_details_ds .only {
    font-size: 28px;
  }

  .box_details_ds .price {
    font-size: 35px;
  }

  .box_details_ds p {
    font-size: 18px;
  }

  .item_field_single {
    flex-basis: 100%;
  }

  .man_inputStyle {
    max-width: 100%;
  }

  .item_field_single .sellect_option {
    margin-bottom: 15px;
  }

  .input_fields_control {
    margin-bottom: 20px;
    display: block;
  }

  .title_comp_wed_add_cus h2 {
    font-size: 25px;
  }

  .enable_show_deadman {
    display: block;
  }

  .enable_show_deadman .label_left {
    margin-bottom: 10px;
  }

  .effect_image_options {
    display: block;
  }

  .options_select {
    padding: 5px 0;
  }

  .select_code~.item_field_single {
    flex-basis: 68%;
  }

  .double_input_row .half_row_hor_input {
    width: 50%;
  }

  .uploading_single_img_group h2 {
    font-size: 20px;
  }

  .btn_group_r button {
    padding: 10px !important;
  }

  .btn_group_r {
    gap: 10px;
  }

  .step2_list {
    margin-top: 10px;
  }

  .wrapbox_image_pc {
    padding: 20px;
    align-items: center;
  }

  .wrapbox_image_pc .head h3 {
    font-size: 25px;
  }

  .image_single_pc {
    bottom: 80px;
    max-width: 100%;
  }

  div[name='section3'] .wrapbox_image_pc .head h3 {
    margin-bottom: 250px;
  }

  .box_3phone_wrap_image {
    max-width: 100%;
    justify-content: center;
  }

  .box_3phone_wrap_image img {
    margin-bottom: 200px;
    width: 400px;
    max-width: unset;
    object-fit: contain;
  }

  .box_3phone_section .head {
    padding: 15px;
    left: 0;
    right: 0;
    bottom: 40px;
  }

  .image_show_demo_wish {
    justify-content: end;
  }

  .image_show_demo_wish img {
    width: 830px;
    max-width: unset;
  }

  .box_save_wish .grid_box_wish h3 {
    font-size: 30px;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .checkbox-label .icon_toogle {
    display: block;
    margin-top: 5px;
  }

  .checkbox:checked+.checkbox-label+.infomationDetails {
    display: none;
  }

  .box_top_footer {
    padding: 30px 15px;
  }

  .box_top_footer .box_wrap .text p {
    font-size: 21px;
  }

  .box_top_footer .box_wrap .text h3 {
    font-size: 26px;
  }

  .bock_content_modal {
    overflow: auto;
  }

  .block_step {
    width: 100%;
    flex: none;
    scroll-behavior: smooth;
    max-width: fit-content;
    scroll-margin-block-start: inherit;
  }

  .content_wedding_vow .content {
    padding: 0 20px;
    font-size: 16px;
  }
}

@media (max-width: 414px) {
  .image_single_pc img {
    width: 550px;
    max-width: unset;
  }
}

@media (max-width: 375px) {
  .image_single_pc img {
    width: 500px;
    max-width: unset;
  }
}

@media (min-width: 991px) {
  .navbar-toggler {
    display: none;
  }

  .view_experience {
    width: 120px;
    height: 120px;
    position: fixed;
    right: 60px;
    -webkit-animation: upanddown2 1s infinite linear alternate;
    animation: upanddown2 1s infinite linear alternate;
    z-index: 99999;
    cursor: pointer;
  }

  .double_input_row .half_row_hor_input.w_67_sm_lg {
    width: 67%;
  }
}

@media (min-width: 1200px) and (max-width: 1369px) {
  .image_show_demo_wish {
    max-width: 1200px;
  }

  .section_wrap_type_ds .head h2 {
    font-size: 35px;
  }

  .menu_bar_fix_top {
    min-width: 125px;
  }

  .menu_bar_fix_top ul {
    padding: 10px;
  }

  .menu_bar_fix_top ul li {
    text-align: right;
  }

  .box_abs_image_video {
    transform: translate3d(20%, 0, 0);
  }

  .section_wrap_type_ds .head h2 {
    font-size: 35px;
  }
}

@media (min-width: 1120px) and (max-width: 1199px) {
  html {
    overflow: unset;
    scroll-snap-type: unset;
    scroll-padding: unset;
    height: 100vh;
  }

  body {
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
  }

  .image_single_pc {
    bottom: 80px;
  }

  .wrapbox_image_pc {
    align-items: center;
  }

  div[name='section3'] .wrapbox_image_pc .head h3 {
    margin-bottom: 250px;
  }

  .section_wrap_type_ds .head h2 {
    font-size: 30px;
  }

  .slide-track .slide-item .title h3 {
    font-size: 22px;
  }
}

@media (min-width: 1024px) and (max-width: 1099px) {
  .main_tit .tit_type2 {
    font-size: 30px;
  }

  .box_3phone_wrap_image {
    margin: 0 0 0 200px;
  }

  .image_show_demo_wish img {
    width: 800px;
  }

  .section_wrap_type_ds .head h2 {
    font-size: 30px;
  }

  .slide-track .slide-item .box-image img {
    object-fit: cover;
    height: 300px;
  }

  .tab-content {
    width: 65%;
  }
}

/* .section-mb {
  background-color: #f5c2c6 !important;
} */

.fadeIn1 {
  animation-name: fadeIn1;
  -webkit-animation-name: fadeIn1;

  animation-duration: 5s;
  -webkit-animation-duration: 5s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  visibility: visible !important;
}

@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }

  20%,
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn1 {
  0% {
    opacity: 0;
  }

  20%,
  100% {
    opacity: 1;
  }
}

.fadeIn2 {
  animation-name: fadeIn2;
  -webkit-animation-name: fadeIn2;

  animation-duration: 5s;
  -webkit-animation-duration: 5s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  visibility: visible !important;
}

@keyframes fadeIn2 {

  0%,
  20% {
    opacity: 0;
  }

  40%,
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn2 {

  0%,
  20% {
    opacity: 0;
  }

  40%,
  100% {
    opacity: 1;
  }
}

.fadeIn3 {
  animation-name: fadeIn3;
  -webkit-animation-name: fadeIn3;

  animation-duration: 5s;
  -webkit-animation-duration: 5s;

  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;

  visibility: visible !important;
}

@keyframes fadeIn3 {

  0%,
  40% {
    opacity: 0;
  }

  60%,
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn3 {

  0%,
  40% {
    opacity: 0;
  }

  60%,
  100% {
    opacity: 1;
  }
}

@keyframes upanddown2 {
  0% {
    bottom: 80px;
  }

  100% {
    bottom: 60px;
  }
}

@-webkit-keyframes upanddown2 {
  0% {
    bottom: 260px;
  }

  100% {
    bottom: 240px;
  }
}

@keyframes fadeInLeftContent {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-300%, 0, 0);
    transform: translate3d(-300%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeftPhone {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeftmd {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes fadeInLeftsm {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes fadeInLeftxs {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(300%, 0, 0);
    transform: translate3d(300%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft_phone {
  -webkit-animation: fadeInLeftPhone;
  animation: fadeInLeftPhone;
  animation-duration: 2s;
}

.animate__fadeInLeft_Content {
  -webkit-animation: fadeInLeftContent;
  animation: fadeInLeftContent;
  animation-duration: 2s;
}

@media (min-width: 1200px) and (max-width: 1369px) {
  .animate__fadeInLeft_phone {
    -webkit-animation: fadeInLeftmd;
    animation: fadeInLeftmd;
    animation-duration: 2s;
  }
}

@media (min-width: 1000px) and (max-width: 1099px) {
  html {
    overflow: unset;
    scroll-snap-type: unset;
    scroll-padding: unset;
    height: 100vh;
  }

  body {
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
  }

  .wrap_ds_review {
    padding: 40px 0;
  }

  .animate__fadeInLeft_phone {
    -webkit-animation: fadeInLeftsm;
    animation: fadeInLeftsm;
    animation-duration: 2s;
  }
}

@media (max-width: 1199px) {

  .my_input_form_data_group,
  .header_editpage,
  .savesetting_btn {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .box_abs_image_video {
    transform: translate3d(0, 0, 0);
  }

  .animate__fadeInLeft_phone {
    -webkit-animation: fadeInLeftxs;
    animation: fadeInLeftxs;
    animation-duration: 2s;
  }
}

@media (max-width: 767px) {
  .section_video_NFT {
    padding: 20px 0;
  }

  .box_abs_image_video {
    position: relative;
    transform: translate3d(0, 0, 0);
    margin-top: 30px;
  }

  .background_couple {
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
}

.comment-detail {
  padding-bottom: 1080px;
}

.hidden {
  display: none !important;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }

  .tab-content {
    width: 65%;
  }
}


@font-face {
  font-family: 'SFUDinLight';
  src: local('SFUDinLight'), url(./fonts/SFUDinLight.ttf) format('truetype');
}

@font-face {
  font-family: 'BUNDTCAKEEXTRABOLD';
  src: local('BUNDTCAKEEXTRABOLD'), url(./fonts/BUNDTCAKEEXTRABOLD.ttf) format('truetype');
}

@font-face {
  font-family: 'MaitreeLight';
  src: local('MaitreeLight'), url(./fonts/MaitreeLight.ttf) format('truetype');
}

@font-face {
  font-family: 'MavenPro';
  src: local('MavenPro'), url(./fonts/MavenPro.ttf) format('truetype');
}

@font-face {
  font-family: 'NETTOOT';
  src: local('NETTOOT'), url(./fonts/NETTOOT.ttf) format('truetype');
}

@font-face {
  font-family: 'NEXABOLD';
  src: local('NEXABOLD'), url(./fonts/NEXABOLD.ttf) format('truetype');
}

@font-face {
  font-family: 'NEXALIGHT';
  src: local('NEXALIGHT'), url(./fonts/NEXALIGHT.ttf) format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay-VariableFontwght';
  src: local('PlayfairDisplay-VariableFontwght'), url(./fonts/PlayfairDisplay-VariableFontwght.ttf) format('truetype');
}

@font-face {
  font-family: 'RokkittExtraLight';
  src: local('RokkittExtraLight'), url(./fonts/RokkittExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'SFUFeniceRegular';
  src: local('SFUFeniceRegular'), url(./fonts/SFUFeniceRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'SFUGaramondCondensedBold';
  src: local('SFUGaramondCondensedBold'), url(./fonts/SFUGaramondCondensedBold.ttf) format('truetype');
}

@font-face {
  font-family: 'SFUGlytusRegular';
  src: local('SFUGlytusRegular'), url(./fonts/SFUGlytusRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'SFUTrajanRegular';
  src: local('SFUTrajanRegular'), url(./fonts/SFUTrajanRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'SVNWallows';
  src: local('SVNWallows'), url(./fonts/SVNWallows.ttf) format('truetype');
}
